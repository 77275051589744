<template>
  <div>
    <v-radio-group v-model="radioGroup">
      <v-data-table
        :headers="headers"
        :items="tenantsList"
        sort-by="calories"
        class="elevation-0"
        :items-per-page="5"
        no-data-text="Nenhum dado encontrado"
        no-results-text="Nenhum resultado encontrado"
        :footer-props="{
          'items-per-page-text': 'Itens por página:',
          'items-per-page-all-text': 'Todos',
          'items-per-page-options': [5, 10, 15, 20, -1],
          'page-text': '{0}-{1} de {2}',
        }"
        :search="search"
        :loading="loading"
        loading-text="Carregando dados..."
      >
        <template v-slot:top>
          <v-toolbar flat v-if=!readonly>
            <TenantFormDialog v-if=tenantForm :tenantId="editedIndex" @resetDialog="resetDialog" :readonly="readonly" :isAddingNew="false"></TenantFormDialog>
          </v-toolbar>
          <TenantFormDialog v-else-if=tenantForm :tenantId="editedIndex" @resetDialog="resetDialog" :readonly="readonly" :isAddingNew="false"></TenantFormDialog>
        </template>
        <template v-slot:item.documents="{ item }">
          <v-icon small class="document_icon" :class="hasFileType(item.files, 1) ? 'success_icon' : 'error_icon'">
            mdi-clipboard-account
          </v-icon>
          <v-icon small class="document_icon ml-1" :class="hasFileType(item.files, 2) ? 'success_icon' : 'error_icon'">
            mdi-home
          </v-icon>
          <v-icon small class="document_icon ml-1" :class="hasFileType(item.files, 3) ? 'success_icon' : 'error_icon'">
            mdi-currency-usd
          </v-icon>
        </template>
        <template v-slot:item.personalData.pretensao_id="{ item }">
          {{getPretensionName(item)}}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="editItem(item)" v-if="(isRealEstateUser && !readonly) || isGSInAnalysis" title="Editar">
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon @click="viewItem(item)" v-if="(isGSuser || readonly) && !isGSInAnalysis" title="Visualizar">
            <v-icon small>
              mdi-eye
            </v-icon>
          </v-btn>
          <TenantDelete :itemId=item.id v-if="(isRealEstateUser && !readonly && ![3].includes(situationSolicitation)) || isGSInAnalysis"></TenantDelete>
        </template>
      </v-data-table>
    </v-radio-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TenantFormDialog from "../Tenant/Forms/FormDialog";
import TenantDelete from "./Delete";

export default {
  name: "TenantTable",
  components: {
    TenantFormDialog,
    TenantDelete
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: "Nome completo", value: "personalData.nome" },
      { text: "Pretendente à", value: "personalData.pretensao_id" },
      { text: "CPF", value: "personalData.cpf" },
      { text: "Documentos", value: "documents" },
      { text: "Ações", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: null,
    radioGroup: 1,
    tenantForm: true
  }),
  computed: {
    ...mapGetters([
      'isRealEstateUser',
      'isGSuser',
      'tenants',
      'pretensionSolicitationList',
      'isGSInAnalysis',
      'situationSolicitation'
    ]),
    tenantsList() {
      return this.tenants
    }
  },
  methods: {
    editItem(item) {
      this.resetDialog();
      this.editedIndex = this.tenantsList.indexOf(item);
    },
    viewItem(item) {
      this.resetDialog();
      this.editedIndex = this.tenantsList.indexOf(item);
    },
    resetDialog() {
      this.$emit('reloadPendencies')
      this.editedIndex = null;
      this.tenantForm = false;
      this.tenantForm = true;
    },
    getPretensionName(item) {
      let pretension = this.pretensionSolicitationList.find(p => {
        return p.id == item.personalData.pretensao_id
      })
      return this.isObject(pretension) ? pretension.descricao : ""
    },
    hasFileType(files, typeId) {
      let hasFile = files.find(file => {
        return file.tipo_documento_id == typeId
      })
      return hasFile !== undefined ? true : false
    },
  },
};
</script>

<style>
.document_icon {
  display: inline-block;
  border-radius: 60px;
  border: 1px solid;
  padding: 0.3em 0.3em;
}
</style>
