<template>
  <v-container>
    <v-row>
      <v-col>
        <v-switch
          v-model="compoe_renda"
          label="Compõe renda"
          class="ma-0"
          :readonly="(readonly && !(situationSolicitation == 2 && userRole == 3)) || ((situationSolicitation == 3 && [4,5].includes(userRole)) && !isAddingNew)"
          :disabled="is_tenant"
        ></v-switch>
      </v-col>
      <v-col v-if="this.usuarioGs && tenant_nao_compoe_renda">
        <v-switch
          v-model="enviar_para_analise"
          label="Enviar para análise"
          class="ma-0"
          color="red"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col md="4">
        <v-select
          no-data-text="Nenhum dado encontrado"
          item-text="descricao"
          item-value="id"
          v-model="perfil_financeiro_id"
          :items="op_perfil_financeiro"
          label="Perfil financeiro por:"
          :readonly="(readonly && !(situationSolicitation == 2 && userRole == 3)) || ((situationSolicitation == 3 && [4,5].includes(userRole)) && !isAddingNew)"
          :disabled=makesUpIncome
          ></v-select>
        </v-col>
      <v-col md="4">
        <v-select
          no-data-text="Nenhum dado encontrado"
          item-text="descricao"
          item-value="id"
          v-model="origem_renda_id"
          :items="op_origem_renda"
          label="Origem renda principal"
          :readonly="(readonly && !(situationSolicitation == 2 && userRole == 3)) || ((situationSolicitation == 3 && [4,5].includes(userRole)) && !isAddingNew)"
          :disabled=makesUpIncome
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4">
        <MoneyField
          label="Renda principal"
          v-model="valor_renda"
          :readonly="(readonly && !(situationSolicitation == 2 && userRole == 3)) || ((situationSolicitation == 3 && [4,5].includes(userRole)) && !isAddingNew)"
          :disabled=makesUpIncome
        ></MoneyField>
      </v-col>
      <v-col v-if="situationSolicitation == 2 && userRole == 3">
        <v-btn color="red" @click="emitirAlteracaoRenda" outlined rounded> Alterar Rendas </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mapFields } from 'vuex-map-fields'

export default {
  name: "FinancialProfileFormTenant",
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    usuarioGs: {
     type: Boolean,
     default: false 
    },
    isAddingNew: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    teste: ""
  }),
  computed: {
    ...mapFields([
      'newTenant.financialProfile.compoe_renda',
      'newTenant.financialProfile.perfil_financeiro_id',
      'newTenant.financialProfile.valor_renda',
      'newTenant.financialProfile.origem_renda_id',
      'newTenant.personalData.pretensao_id',
      'newTenant.financialProfile.enviar_para_analise'
    ]),
    ...mapGetters([
      "incomeSourceList",
      "financialProfileList",
      "makesUpIncome",
      "userRole",
      "situationSolicitation"
    ]),
    op_origem_renda() {
      return this.incomeSourceList.filter(item => {
        if(this.perfil_financeiro_id == 2) {
          return item.tipo_origem_renda == "I"
        } if(this.perfil_financeiro_id == 3) {
          return item.tipo_origem_renda == "F"
        }
        return item
      });
    },
    op_perfil_financeiro() {
      return this.financialProfileList;
    },
    is_tenant() {
      return this.pretensao_id == 1
    },
    tenant_nao_compoe_renda() {
      return this.compoe_renda == false
    }
  },
  watch: {
    is_tenant(val) {
      if(val) {
        this.compoe_renda = true
      }
    }
  },
  created() {
    this.getIncomeSourceList();
    this.getFinancialProfileList();
  },
  methods: {
    ...mapActions([
      "getIncomeSourceList",
      "getFinancialProfileList"
    ]),
    emitirAlteracaoRenda () {
      this.$emit('alterar-renda-via-gs')
    },
    emitirAlteracao () {
      this.$emit('alteracao-via-gs')
    }
  },
};
</script>
