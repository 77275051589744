<template>
  <v-form v-model="valid">
    <h5 class="primary--text bold" v-if="title">Observações da locação</h5>
    <h6 v-if="title" class="text_dark_grey">Conte-nos alguma informação relevante sobre essa locação e seus pretendentes.</h6>
    <v-container class="ml-0 pl-0">
      <v-row>
        <v-col>
          <v-textarea
            v-model="observacao"
            label="Observação"
            auto-grow
            rows="1"
            :loading="loadingObs"
            :readonly="!this.isGSInAnalysis && this.readonly"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0" cols="2">
              <v-btn outlined class="pa-0 px-5" rounded color="primary" required
                @click="saveRealty" v-if="this.isGSInAnalysis">
                Salvar alterações
              </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NewRequestObservation',
  props: {
    title: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    valid: false,
    loadingObs: false
  }),
  computed: {
    ...mapFields([
      'request.observacao'
    ]),
    ...mapGetters([
      'isGSInAnalysis',
      'canPerformAction'
    ])
  },
  methods: {
    ...mapActions(['saveRequest']),
    async saveRealty() {
      this.loadingObs = true;
      await this.saveRequest().finally(() => {
          this.loadingObs = false
        })
    }
  }
}
</script>
