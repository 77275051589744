<template>
  <v-dialog v-model="dialog" max-width="90%" @click:outside="closeDialog">
    <template v-slot:activator="{ on, attrs }" v-if="this.isGSInAnalysis || !this.readonly">
      <v-btn color="primary" dark class="mb-2" @click="openDialog" v-bind="attrs" v-on="on" rounded>
        Adicionar
      </v-btn>
    </template>
    <v-card v-if="dialog">
      <v-card-title>
        <v-row>
          <v-col>
            <h5 class="primary--text bold">{{ readonly ? 'Parte' : 'Nova parte' }}</h5>
          </v-col>
          <v-col align="right">
            <v-btn color="black" right icon dark @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>

        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row justify="center">
              <v-expansion-panels v-model="openedPanel" accordion>
                <v-expansion-panel @click="changePanel">
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col md="2" class="pa-0">
                        <v-chip color="primary" small>
                          <span class="white--text headline">1</span>
                        </v-chip>
                        <span class="ml-1"> Dados pessoais</span>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <PersonalDataFormTenant :readonly="!isGSInAnalysis && readonly"></PersonalDataFormTenant>
                    <v-row justify="end" class="mb-1">
                      <v-btn color="primary" @click="nextPanel" :loading=savingTenant rounded> Avançar </v-btn>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel @click="changePanel">
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col md="2" class="pa-0">
                        <v-chip color="primary" small>
                          <span class="white--text headline">2</span>
                        </v-chip>
                        <span class="ml-1"> Perfil financeiro</span>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <FinancialProfileFormTenant @alterar-renda-via-gs="perfilRealAlteracaoRenda" :usuarioGs="isGSuser"
                      :readonly="!isGSInAnalysis && readonly" :isAddingNew="isAddingNew"></FinancialProfileFormTenant>
                    <div v-for="(form, i) in financialProfileForms" :key="form">
                      <FinancialProfileOtherIncome
                        :ref="i"
                        :formId="i"
                        :readonly="!isGSInAnalysis && readonly"
                        @delete="deleteOtherIncome"
                        :usuarioGs="isGSuser"
                        :isAddingNew="isAddingNew"
                        :showDelete="
                          form === financialProfileForms &&
                          financialProfileForms > 1"
                      ></FinancialProfileOtherIncome>
                    </div>
                    <v-row>
                      <v-btn text small color="primary" @click="addForm" v-if="(this.isGSInAnalysis || !this.readonly) && !(this.situationSolicitation == 3 && [4,5].includes(userRole) && !isAddingNew)">
                        + Adicionar outra renda
                      </v-btn>
                    </v-row>
                    <v-row justify="end" class="mb-1">
                      <v-btn class="mr-1" @click="backPanel" rounded> Voltar </v-btn>
                      <v-btn color="primary" @click="nextPanel" :loading=savingTenant rounded> Avançar </v-btn>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel @click="changePanel">
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col md="2" class="pa-0">
                        <v-chip color="primary" small>
                          <span class="white--text headline">3</span>
                        </v-chip>
                        <span class="ml-1"> Endereço</span>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <AddressFormTenant :readonly="!isGSInAnalysis && readonly"></AddressFormTenant>
                    <v-row justify="end" class="mb-1">
                      <v-btn color="" class="mr-1" @click="backPanel" rounded>
                        Voltar
                      </v-btn>
                      <v-btn color="primary" @click="nextPanel" :loading=savingTenant rounded> Avançar </v-btn>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel @click="changePanel">
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col md="2" class="pa-0">
                        <v-chip color="primary" small>
                          <span class="white--text headline">4</span>
                        </v-chip>
                        <span class="ml-1"> Documentos</span>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <FileInput :action=!inRegistration :readonly="!isGSInAnalysis && readonly"></FileInput>
                    <v-row justify="end" class="mb-1">
                      <v-btn color="" class="mr-1" @click="backPanel" rounded>
                        Voltar
                      </v-btn>
                      <v-btn color="primary" @click="conclude" :loading=savingTenant rounded> {{ readonly ? 'Salvar' : 'Concluir' }} </v-btn>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-container>
        </v-form>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PersonalDataFormTenant from './PersonalData'
import FinancialProfileFormTenant from './FinancialProfile'
import AddressFormTenant from './Address'
import FileInput from '../../Generics/FileInput'
import FinancialProfileOtherIncome from './OtherIncome'
import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
  name: 'TenantFormDialog',
  components: {
    PersonalDataFormTenant,
    FinancialProfileFormTenant,
    AddressFormTenant,
    FileInput,
    FinancialProfileOtherIncome
  },
  props: {
    tenantId: {
      type: Number,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isAddingNew: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    valid: false,
    dialog: false,
    financialProfileForms: 1,
    openedPanel: 0,
    id: null,
    savingTenant: false
  }),
  computed: {
    ...mapGetters(['tenant', 'newTenant', 'inRegistration', 'isGSuser', 'isGSInAnalysis', 'situationSolicitation', 'userRole'])
  },
  watch: {
    tenantId (val) {
      if (this.exists(val)) {
        this.dialog = true
        const tenant = this.tenant(val)
        this.setNewTenant(tenant)
        this.financialProfileForms = tenant.financialProfile.origem_outras_rendas.length
      } else {
        this.financialProfileForms = 1
      }
    },
    dialog (newVal, oldVal) {
      if (newVal != oldVal && newVal == false) {
        this.$emit('resetDialog')
      }
    }
  },
  methods: {
    ...mapMutations(['addTenantToTenants', 'resetNewTenant', 'setNewTenant']),
    ...mapActions(['saveTenant']),
    addForm () {
      this.financialProfileForms++
    },
    async nextPanel () {
      if (this.readonly) {
        this.openedPanel++
        return
      }
      this.saveData()
      this.openedPanel++
    },
    async changePanel () {
      if (this.readonly) {
        return
      }
      await this.saveData()
    },
    backPanel () {
      this.openedPanel--
    },
    deleteOtherIncome (id) {
      this.financialProfileForms--
    },
    async conclude () {
      if (!this.valid || !this.$refs.form.inputs?.[1]?.value) {
        this.$toastr.e('Por favor, preencha todos os campos obrigatórios.');
        return
      }
      await this.saveData().then(() => {
        this.closeDialog()
      })
    },
    closeDialog () {
      if (!this.exists(this.tenantId) && this.exists(this.newTenant.id)) {
        this.addTenantToTenants()
      }
      this.resetNewTenant()
      this.dialog = false
    },
    perfilRealAlteracaoRenda () {
      if (this.isGSuser) {
        this.openedPanel++
        this.saveData()
      } else {
        this.$toastr.e('Ocorreu um erro ao alterar a renda.')
      }
    },
    async saveData () {
      if (this.valid) {
        this.savingTenant = true
        await this.saveTenant()
          .catch(err => {
            this.$toastr.e(err.response.data.message)
          })
          .finally(() => {
            this.savingTenant = false
          })
      } else {
        this.$toastr.e('O campo CPF é obrigatório para que os dados sejam salvos')
      }
    },
    openDialog() {
      this.isAddingNew = true;
    }
  }
}
</script>
