<template>
  <div>
    <v-row>
      <v-col>
        <v-file-input
          ref="uploader"
          multiple
          label="File input"
          truncate-length="50"
          class="hidden"
          @change="selectFiles"
          v-if="!readonly"
        ></v-file-input>
        <v-btn
          color="primary"
          @click="onUploadBtnClick"
          outlined
          :loading="savingFiles"
          v-if="!readonly"
          :disabled=disabled
          rounded
        >
          Selecionar arquivos...
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mb-4" v-show="files.length">
      <v-col>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" v-if=!disabled width="120px">Ações</th>
                <th class="text-left" width="200px">Nome do arquivo</th>
                <th class="text-left" width="290px">Classificar documento</th>
                <th class="text-left" width="250px">Observações</th>
                <th class="text-left" v-if="action" width="250px">Avaliações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(file, i) in files" :key="file.name">
                <td v-if=!disabled>
                  <v-btn class="ma-0 pa-0" text icon @click="showFile(file)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-0 pa-0"
                    text
                    icon
                    @click="downloadFile(file)"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                  <!-- <v-btn
                    class="ma-0 pa-0"
                    text
                    icon
                    @click="removeFile(file, i)"
                    v-if="!readonly"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn> -->
                  <DeleteItemDialog @delete="removeFile(file, i)" :loading=loadingDelete v-if="!readonly"></DeleteItemDialog>
                </td>
                <td>
                  <v-text-field
                    dense
                    v-model="file.nome_documento"
                    class="ma-0 pa-0"
                    hide-details
                    placeholder="Nome do arquivo"
                    :readonly=readonly
                    :disabled=disabled
                  ></v-text-field>
                </td>
                <td>
                  <v-select
                    no-data-text="Nenhum dado encontrado"
                    item-text="descricao"
                    item-value="id"
                    dense
                    v-model="file.tipo_documento_id"
                    :items="op_tipo_documento"
                    class="ma-0 pa-0"
                    hide-details
                    required
                    :rules="[(v) => !!v || 'Tipo de documento é obrigatório']"
                    :readonly="readonly"
                    :disabled=disabled
                  ></v-select>
                </td>
                <td>
                  <v-text-field
                    dense
                    v-model="file.observacao"
                    class="ma-0 pa-0"
                    hide-details
                    :readonly="readonly"
                    :disabled=disabled
                  ></v-text-field>
                </td>
                <td v-if="action">
                  <MultiColorSwitch
                    v-model="file.pendencia"
                    :readonly="!isGSuser || !inAnalysis"
                    :disabled=disabled
                  ></MultiColorSwitch>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import DeleteItemDialog from './DeleteItemDialog'

export default {
  name: 'FileInput',
  components: {
    DeleteItemDialog
  },
  props: {
    action: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    savingFiles: false,
    loadingDelete: false
  }),
  computed: {
    ...mapFields(['newTenant.files']),
    ...mapGetters(['documentTypeList', 'isGSuser', 'inAnalysis']),
    op_tipo_documento () {
      return this.documentTypeList.filter(d => {
        return d.referencia == 1
      })
    }
  },
  created () {
    this.getDocumentTypeList()
  },
  methods: {
    ...mapActions([
      'getDocumentTypeList',
      'savePersonFiles',
      'deletePersonFile',
      'downloadPersonFile',
      'showPersonFile'
    ]),
    onUploadBtnClick () {
      this.$refs.uploader.$refs.input.click()
    },
    selectFiles (files) {
      this.savingFiles = true
      this.savePersonFiles(files).then(() => {
        this.savingFiles = false
      })
    },
    removeFile (file, i) {
      this.loadingDelete = true
      this.deletePersonFile(file).then((resp) => {
        file.is_excluido = true
        this.files.splice(i, 1)
        this.$toastr.s(resp.message)
      })
        .catch(err => {
          this.$toastr.e(err.response.data.message)
        })
        .finally(() => {
          this.loadingDelete = false
        })
    },
    downloadFile (file) {
      this.downloadPersonFile(file.id).then((resp) => {
        this.fileDownload(resp, file.nome_documento + '.' + file.extensao_arquivo)
      })
    },
    showFile (file) {
      this.showPersonFile(file.id).then((resp) => {
        this.fileShow(resp)
      })
    }
  }
}
</script>

<style>
.hidden {
  display: none;
}
.border-outset {
  border: outset;
}
</style>
