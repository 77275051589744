<template>
  <v-form v-model="valid">
    <h5 class="primary--text bold" v-if="title">Dados do imóvel</h5>
    <h6 v-if="title" class="text_dark_grey">Preencha as informações do imóvel pretendido a locação.</h6>
    <v-container class="ml-0 pl-0">
      <v-row>
        <v-col md="3">
          <v-text-field
            v-model="codigo"
            label="Código do Imóvel"
            required
            @blur="searchRealty(readonly)"
            :readonly=readonly
          ></v-text-field>
        </v-col>

        <v-col md="3">
          <v-text-field
            v-model="cpf_cnpj_proprietario"
            v-mask="['###.###.###-##', '##.###.###/####-##']"
            label="CPF ou CNPJ do Proprietário do Imóvel"
            required
            :loading="loadingRealty"
            :readonly="!this.isGSInAnalysis && this.readonly"
            :rules="[rules.required, isaCNPJ() ? rules.validCNPJ : rules.validCPF]"
          ></v-text-field>
        </v-col>

        <v-col md="6">
          <v-text-field
            v-model="nome_proprietario"
            label="Nome proprietário"
            required
            :loading="loadingRealty"
            :readonly="!this.isGSInAnalysis && this.readonly"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="2">
          <MoneyField
            label="Aluguel"
            v-model="valor_aluguel"
            required
            :loading="loadingRealty"
            :readonly="!this.isGSInAnalysis && this.readonly"
          ></MoneyField>
        </v-col>
        <v-col md="2">
          <MoneyField
            label="Condomínio"
            v-model="valor_condominio"
            required
            :loading="loadingRealty"
            :readonly="!this.isGSInAnalysis && this.readonly"
          ></MoneyField>
        </v-col>
        <v-col md="2">
          <MoneyField
            label="IPTU"
            v-model="valor_iptu"
            required
            :loading="loadingRealty"
            :readonly="!this.isGSInAnalysis && this.readonly"
          ></MoneyField>
        </v-col>
        <v-col md="2">
          <MoneyField
            label="Água"
            v-model="valor_agua"
            required
            :loading="loadingRealty"
            :readonly="!this.isGSInAnalysis && this.readonly"
          ></MoneyField>
        </v-col>
        <v-col md="2">
          <MoneyField
            label="Luz"
            v-model="valor_luz"
            required
            :loading="loadingRealty"
            :readonly="!this.isGSInAnalysis && this.readonly"
          ></MoneyField>
        </v-col>
        <v-col md="2">
          <MoneyField
            label="Seguro incêndio"
            v-model="valor_seguro_incendio"
            required
            :loading="loadingRealty"
            :readonly="!this.isGSInAnalysis && this.readonly"
          ></MoneyField>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="2">
          <v-select
            no-data-text="Nenhum dado encontrado"
            item-text="nome"
            item-value="id"
            v-model="tipo_imovel"
            :items="op_tipo_imovel"
            label="Tipo de imóvel"
            required
            :loading="loadingRealty"
            :readonly="!this.isGSInAnalysis && this.readonly"
          ></v-select>
        </v-col>
        <v-col md="3" class="align-self-md-center pa-6">
          <v-row>
            <v-col class="pa-0" cols="10">
              <v-text-field
                v-model="cep"
                v-mask="'#####-###'"
                label="CEP"
                class="mr-4"
                required
                :loading="loadingAddress || loadingRealty"
                :readonly="!this.isGSInAnalysis && this.readonly"
              ></v-text-field>
            </v-col>
            <v-col class="pa-0" cols="2">
              <v-btn
                title="Consultar CEP"
                outlined
                class="pa-0 mt-2"
                rounded
                color="primary"
                required
                @click="getAddress"
                v-if="this.isGSInAnalysis || !this.readonly"
              >
                <v-icon>mdi-magnify</v-icon>
                CEP
          </v-btn>
        </v-col>
          </v-row>
        </v-col>
        <v-col md="3" class="pl-6">
          <v-text-field
            v-model="logradouro"
            label="Endereço"
            required
            :loading="loadingAddress || loadingRealty"
            :readonly="!this.isGSInAnalysis && this.readonly"
          ></v-text-field>
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model="numero"
            label="Número"
            required
            :loading="loadingAddress || loadingRealty"
            :readonly="!this.isGSInAnalysis && this.readonly"
          ></v-text-field>
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model="complemento"
            label="Complemento"
            :loading="loadingAddress || loadingRealty"
            :readonly="!this.isGSInAnalysis && this.readonly"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="4">
          <v-text-field
            v-model="bairro"
            label="Bairro"
            required
            :loading="loadingAddress || loadingRealty"
            :readonly="!this.isGSInAnalysis && this.readonly"
          ></v-text-field>
        </v-col>
        <v-col md="4">
          <v-select
            no-data-text="Nenhum dado encontrado"
            item-text="nome"
            item-value="id"
            v-model="estado_id"
            :items="op_estados"
            label="Estado"
            required
            @change="getCidades"
            :loading="loadingAddress || loadingRealty"
            :readonly="!this.isGSInAnalysis && this.readonly"
          ></v-select>
        </v-col>
        <v-col md="4">
          <v-select
            no-data-text="Nenhum dado encontrado"
            item-text="nome"
            item-value="id"
            v-model="cidade_id"
            :items="op_cidades"
            label="Cidade"
            required
            :loading="loadingCidades || loadingAddress || loadingRealty"
            :readonly="!this.isGSInAnalysis && this.readonly"
          ></v-select>
        </v-col>
        <v-col class="pa-0" cols="2">
              <v-btn title="Consultar CEP" outlined class="pa-0 px-5" rounded color="primary" required
                @click="saveTest" v-if="this.isGSInAnalysis">
                Salvar alterações
              </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import {mask} from 'vue-the-mask'
import isValidCPF from '@/methods/isValidCPF'
import isaValidCNPJ from '@/methods/isaValidCNPJ'

export default {
  name: 'NewRequestRealty',
  directives: {mask},
  props: {
    title: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    valid: false,
    rules: {
      required: value => !!value || 'Campo obrigatório',
      validCPF: value => isValidCPF(value) || 'Informe um CPF válido',
      validCNPJ: value => isaValidCNPJ(value) || 'Informe um CNPJ válido'
    },
    op_tipo_imovel: [
      {
        nome: 'Residencial',
        id: '1'
      },
      {
        nome: 'Não residencial',
        id: '2'
      }
    ],
    loadingCidades: false,
    loadingAddress: false,
    loadingRealty: false,
    codeSearched: null
  }),
  computed: {
    ...mapFields([
      'request.realty.codigo',
      'request.realty.cpf_cnpj_proprietario',
      'request.realty.nome_proprietario',
      'request.realty.valor_aluguel',
      'request.realty.valor_condominio',
      'request.realty.valor_iptu',
      'request.realty.valor_agua',
      'request.realty.valor_luz',
      'request.realty.valor_seguro_incendio',
      'request.realty.tipo_imovel',
      'request.realty.cep',
      'request.realty.logradouro',
      'request.realty.numero',
      'request.realty.complemento',
      'request.realty.bairro',
      'request.realty.estado_id',
      'request.realty.cidade_id'
    ]),
    ...mapGetters(['statesList', 'citiesList', 'isGSInAnalysis']),
    op_estados () {
      return this.statesList
    },
    op_cidades () {
      return this.citiesList
    }
  },
  watch: {
    estado_id(newValue) {
      if (newValue) this.getCidades(newValue);
    },
    citiesList(newCities) {
      if (newCities.length === 0) return;
      if (this.cidade_ibge) {
        const city = newCities.find((c) => c.ibge === this.cidade_ibge);
        if (city) this.cidade_id = city.id;
        this.cidade_ibge = null; // Clear the pending IBGE once it's processed
      }
    }
  },
  created() { this.getStatesList(); },
  methods: {
    ...mapActions([
      'getStatesList',
      'getCitiesList',
      'getAdrressByCep',
      'getRealty',
      'saveRequest'
    ]),
    getCidades (val) {
      this.loadingCidades = true;
      this.getCitiesList(val).then(() => (this.loadingCidades = false));
    },
    getAddress () {
      this.loadingAddress = true;
      this.estado_id = null;
      this.getAdrressByCep(this.cep)
        .then((resp) => {
          this.bairro = resp.bairro;
          this.logradouro = resp.logradouro;
          // Set estado_id and let the watcher handle fetching cities
          const foundState = this.statesList.find((s) => s.uf.substring(0, 2) === resp.uf);
          if (foundState) {
            this.estado_id = foundState.id; // This will trigger the watcher and load cities
            this.cidade_ibge = +(resp.ibge.substring(0, 6));
          }
        })
        .catch(err => {
          this.$toastr.e(err.response.data.message)
        })
        .finally(() => {
          this.loadingAddress = false
        })
    },
    searchRealty(readonly = false) {
      if (!readonly && this.codeSearched != this.codigo) {
        this.codeSearched = this.codigo;
        this.loadingRealty = true;
        this.getRealty(this.codigo)
        .then((resp) => {
          if (resp.length !== 0) {
            this.cpf_cnpj_proprietario = resp.cpf_cnpj_proprietario
            this.nome_proprietario = resp.nome_proprietario
            this.valor_aluguel = resp.valor_aluguel
            this.valor_condominio = resp.valor_condominio
            this.valor_iptu = resp.valor_iptu
            this.valor_agua = resp.valor_agua
            this.valor_luz = resp.valor_luz
            this.tipo_imovel = resp.tipo_imovel
            this.cep = resp.cep
            this.logradouro = resp.logradouro
            this.numero = resp.numero
            this.complemento = resp.complemento
            this.bairro = resp.bairro
            this.estado_id = resp.city.state.id
            this.cidade_id = resp.cidade_id
          }
        })
        .catch(err => {
            this.$toastr.e(err.response.data.message)
          })
          .finally(() => (this.loadingRealty = false));
      }
    },
    isaCNPJ() {
      if (this.cpf_cnpj_proprietario !== null) {
        return this.cpf_cnpj_proprietario.replace(/[^\d]+/g, '').length > 11
      }
    },
    async saveTest() {
      this.loadingRealty = true;
      await this.saveRequest().finally(() => {
          this.loadingRealty = false
        })
    }
  }
}
</script>
